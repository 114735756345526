* {
  direction: rtl;
}
@font-face {
  font-family: "Assistant";
  src: local("Assistant"), url(./assets/Assistant-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Assistant-SemiBold";
  src: local("Assistant-SemiBold"), url(./assets/Assistant-SemiBold.ttf) format("truetype");
}

.App {
  text-align: center;
  /* height: 100vh; */
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(75,73,108);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  align-items: center;
  padding: 0 2vw;
  height: 6vh;
}

.Header-link {
  font-size: .8em;
  padding: 0 2vw;
}

a {
  text-decoration: none;
  color: rgb(100, 100, 100);
  font-weight: 500;
}

.App-link {
  color: #61dafb;
}

.Policy-title {
  font-size: 1.3em;
  text-align: center;
  padding: 1vw 0;
}
.Policy-subtitle {
  font-size: 1em;
}
.Policy-text {
  font-size: 1em;
  line-height: 23px;
  font-family: "Assistant";
}
.Delete-account-container {
  padding: 50px 0;
  display: flex;
  flex: 1;
  justify-content: center; 
}
.Delete-account-inner-container {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  width: 788px;
  padding: 50px 20px;
  border-top: 1.5px solid rgb(150,150,150);
  border-bottom: 1.5px solid rgb(150,150,150);
}
.credentials-field {
  width: 220px;
  height: 50px;
  background-color: rgba(255,255,255,.75);
  margin-top: 23px;
  border-radius: 8px;
  text-align: center;
}
.credentials-submit-button {
  width: 230px;
  height: 50px;
  background-color: rgb(255,255,255);
  margin-top: 23px;
  border-radius: 88px;
  cursor: pointer;
}
.delete-account-identification-container {
  display: flex;
  flex-direction: column;
  background-color: rgb(75,73,108);
  justify-content: center;
  align-items: center;
  width: 380px;
  padding: 50px 0;
  border-radius: 64px;
  box-shadow: 0 4px 8px 0 rgba(75,73,108,.2), 0 6px 20px 0 rgba(75,73,108,.2);
  margin-right: 50px;
}
.delete-account-view-container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
}
.delete-account-button {
  width: 230px;
  height: 50px;
  background-color: rgb(221,108,77);
  margin-top: 23px;
  border-radius: 88px;
  cursor: pointer;
  border: none;
  color: white;
  font-family: 'Assistant-SemiBold';
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .Delete-account-container {
    padding: 0;
  }
  .Delete-account-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 358px;
    padding: 40px 20px;
    border-top: 1px solid rgb(200,200,200);
    border-bottom: 1px solid rgb(200,200,200);
    align-items: center;
    margin-top: 40px;
  }
  .delete-account-identification-container {
    width: 100%;
    margin-right: 0px;
  }
}
